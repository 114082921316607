import React from "react"
import MainLayout from "../components/layout/mainLayout"

const About = props => {
  return (
    <>
      <MainLayout>
        <main className="About">
          <div className="About-image" />
          <div className="About-text">
            <p>
              Whether I’m at the helm of a new script, older opera score, or
              something in between, my focus is on research, storytelling, and
              world-building. As a director, my approach is highly collaborative
              and based in a deep-seated respect for performers and designers.
              Through empowering my entire artistic team to contribute wholly
              and personally to our storytelling, I foster the creation of a
              cohesive and impactful final product. No matter when the source
              material was written, my work strives for contemporary social
              relevance and clear, compelling storytelling that entertains as
              much as it provokes. It gives me great satisfaction when an
              audience member connects with a piece they initially thought had
              nothing to say to them. I am often drawn to stories about women,
              new work, and stories about the risks and rewards of human
              connection. I strive to make work that creates conversation;
              either interpersonally, or across a community, or both.
            </p>
            <p>
              I believe live performance is a medium of interpretation and
              imagination on the part of its creators and its audience. My
              visual aesthetic reflects that by being performer-driven, spare,
              and evocative rather than realistic.
            </p>
            <p>
              As an acting teacher and coach, my work is grounded in
              Stanislavski-inspired script analysis combined with an open sense
              of play and emphasis on self discovery. I have helped hundreds of
              students develop a practical, personal approach to acting
              technique and craft and, in the process, practice empathy and
              learn about themselves and their worldview.
            </p>
            <small className="About-headShotCredit">Photographer:<a href="https://headshotsbyholly.com/" target="_blank"> Headshots by Holly</a></small>
          </div>
        </main>
      </MainLayout>
    </>
  )
}

export default About
